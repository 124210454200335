<template>
  <div>
    <div id="chat-button" @click="openChat">
      <img src="wechat.png" alt="Online-chat" width="50" height="50">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openChat() {
      window.open("https://work.weixin.qq.com/kfid/kfc6d0d831f3682b1ad");
    }
  }
}
</script>

<style scoped>
#chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  padding: 0px 0px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 9999;
}
</style>