<template lang="pug">
span {{ translateText }}
</template>
<script>
export default {
  name: "TranslateValue",
  props: {
    sourceObj: {
      type: Object,
      default: null,
    },
    objKey: {
      type: String,
      default: 'name',
    },
  },
  computed: {
    translateText() {
      const propKey = this.objKey + "_" + this.$i18n.locale;
      if (this.sourceObj) {
        return this.sourceObj[propKey] || "";
      }
      return '';
    },
  },
};
</script>
