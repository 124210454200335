<template lang="pug">
//- // branding
//-   // loggin
//- /// ---accessories (your price, listPrice)
//- //// ---parts (your price, factory price)
//- // Not loggin
//- /// ---accessories (list price)
//- //// ---parts ( request quote)
b-col(cols="6", md="12", xl="6")
  div(v-if="!isMedtenCustomer")
    .price-group
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t('search.label.price') }}:
      span.price-value.ml-2(
        v-if="!shouldShowListPrice",
        @click="showBrandingListPrice = true"
      ) 
        |
        |
        span.request-list-price {{ $t('global.label.request_quote') }}
      span.price-value.ml-2(
        v-else,
        :style="primaryColor",
        :class="listPriceClass"
      ) {{ translatePrice(product,"list_price") ? (getCurrency + translatePrice(product,"list_price").toFixed(2)) : (" " + $t('global.label.request_quote')) }}
  div(v-else)
    .price-group(v-if="showYourPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | Your Price :
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2(:style="primaryColor" v-if="product.outsourced") {{ getDealerPrice ? (getCurrency + getDealerPrice.toFixed(2)) : (" " + $t('global.label.request_quote')) }}  
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2(:style="primaryColor" v-else) {{ yourPrice ? (getCurrency + yourPrice.toFixed(2)) : (" " + $t('global.label.request_quote')) }}
    .price-group(v-if="showFactoryPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t('search.label.factory_price') }}:
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2.strikethrough(:style="primaryColor")  {{ translatePrice(product,"price") ? (getCurrency + translatePrice(product,"price").toFixed(2)) : (" " + $t('global.label.request_quote')) }}    
    .price-group(v-if="showListPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t('search.label.price') }}:
      span.price-value.ml-2(
        :style="primaryColor",
        :class="listPriceClass"
      ) {{ translatePrice(product,"list_price") ? (getCurrency + translatePrice(product,"list_price").toFixed(2)) : (" " + $t('global.label.request_quote')) }}
    .price-desc(v-if="showDiscountQuote") {{ $t('search.label.discount_quote') }} 
    .price-group(v-if="showRequestQuote")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t('search.label.price') }}:
      span.price-value.ml-2
        |
        |
        span.request-list-price {{ $t('global.label.request_quote') }}
</template>
<script>
import utils from "@/mixins/utils";

export default {
  mixins: [utils],
  props: {
    productSuppliers:{
      type: Array
    },
    isMedtenCustomer: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    isPart: {
      type: Boolean,
      required: true,
    },
    primaryColor: {
      type: Object,
      default: null,
    },
    primaryBgColor: {
      type: Object,
      default: null,
    },
    buyer_price_info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showBrandingListPrice: false,
    };
  },
  computed: {
    listPriceClass() {
      if (this.buyer_price_info) {
        return "strikethrough";
      }
      return "";
    },
    getDealerPrice(){
      const region = this.customer.marketing_region_id;
      const mrMapping = {
        1: 'us',
        2: 'eu',
        3: 'zh',
      }
      const result = this.productSuppliers.find((item) => {
        if(item.marketing_region_id === region){
          return true
        } else {
          return false
        }
      })
      if (result) {
        return result['dealer_price_' + mrMapping[region]]
      }
      return null
    },
    shouldShowListPrice() {
      return this.getShouldShowListPrice(this.isPart, this.isLoggedIn, this.showBrandingListPrice)
    },
    yourPrice() {
      return this.buyer_price_info?.price;
    },
    //- // branding
    //-   // loggin
    //- /// ---accessories (your price, listPrice)
    //- //// ---parts (your price, factory price)
    //- // Not loggin
    //- /// ---accessories (list price)
    //- //// ---parts ( request quote)
    showFactoryPrice() {
      if (this.isLoggedIn && this.isPart) {
        return true;
      }
      return false;
    },
    showYourPrice() {
      if (this.isLoggedIn) {
        return true;
      }
      return false;
    },
    showListPrice() {
      if (!this.isPart) {
        return true;
      }
    },
    showDiscountQuote() {
      if (!this.isPart && this.isLoggedIn) {
        return false;
      }
      if (this.showListPrice) {
        return true
      }
      return false;
    },
    showRequestQuote() {
      if (!this.isLoggedIn && this.isPart) {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
/* for price */
.price-group {
  display: inherit;
  align-items: center;

  .price-value {
    margin-left: auto;
    color: #38bcd9;
    font-size: 14px;
    font-weight: 600;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #525252;
    }

    .request-list-price {
      color: #8d8601;
      font-weight: bold;
    }
  }
}

.price-desc {
  margin-left: 1.1rem;
  color: #14a757;
  font-size: 14px;
  font-weight: 400;
}

.strikethrough {
  text-decoration: line-through;
}
</style>
