<template lang="pug">
  //- hide-header-close 
  b-modal#website-feedback-modal(title="Product Inquiry" size="lg" header-class="justify-content-center" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc)
    b-container.feedback-modal-body(:style="!isSubmit? getStyle() : ''") 
      .header-title.text-center(v-if="!isSubmit")  Your Feedback
      span.ml-auto.btn-modal-close
        a(href="#" @click="closeModal('website-feedback-modal')")
          b-img(src="@/assets/images/close_modal.svg" width="30") 
      p.header-description(v-if="!isSubmit") We would like your feedback to improve our website.
      .user-input-wrap(v-if="!isSubmit")
        .input-label What is your opinion of this page?
        .user-rating-box 
          .rete-icon-group
            a.rate-item(href="#" @click="selectRateing(1)" :class="{'active': rating_data.rating == 1}")
              b-icon(icon="emoji-angry-fill" aria-hidden="true")
            a.rate-item(href="#" @click="selectRateing(2)" :class="{'active': rating_data.rating == 2}")
              b-icon(icon="emoji-frown-fill" aria-hidden="true")
            a.rate-item(href="#" @click="selectRateing(3)" :class="{'active': rating_data.rating == 3}")
              b-icon(icon="emoji-neutral-fill" aria-hidden="true")
            a.rate-item(href="#"  @click="selectRateing(4)" :class="{'active': rating_data.rating == 4}")
              b-icon(icon="emoji-smile-fill" aria-hidden="true")
            a.rate-item(href="#"  @click="selectRateing(5)" :class="{'active': rating_data.rating == 5}")
              b-icon(icon="emoji-heart-eyes-fill" aria-hidden="true")         
        b-form-group#input-group-1.email-input
          b-form-input#feedback-rating.d-none(v-model="rating_data.rating" type="number" size="lg" v-validate="{required: true}" name="feedback-rating" :state="validateState('feedback-rating')" placeholder="Enter rating" required)
          b-form-invalid-feedback(:state="validateState('feedback-rating')") Please enter your opinion
        .border-bottom.mb-2.mt-4
        .input-label Please select your feedback category below.
        b-row.rating-type-group 
          b-col.rate-item(@click="selectRatingType('Suggestion')" :class="{'active': rating_data.rating_type == 'Suggestion'}")
            | Suggestion
          b-col.rate-item(@click="selectRatingType('Something is not quite right')" :class="{'active': rating_data.rating_type == 'Something is not quite right'}")
            | Something is not 
            br 
            | quite right
          b-col.rate-item(@click="selectRatingType('Compliment')" :class="{'active': rating_data.rating_type == 'Compliment'}")
            | Compliment
        b-form-group#input-group-1.email-input
          b-form-input#feedback-rating-type.d-none(v-model="rating_data.rating_type" type="number" size="lg" v-validate="{required: true}" name="feedback-rating-type" :state="validateState('feedback-rating-type')" placeholder="Enter rating type" required)
          b-form-invalid-feedback(:state="validateState('feedback-rating-type')") Please enter rating type
        .border-bottom.mb-2.mt-4
        .input-label Please leave your feedback below:
        b-form-group#input-group-1.feedback-description
          b-form-textarea#inquary-feedback(v-model="rating_data.feedback" v-validate="{required: true}" rows="4" name="inquary-feedback" :state="validateState('inquary-feedback')" placeholder="Your feedback" required)
          b-form-invalid-feedback(:state="validateState('inquary-feedback')") Please enter part description
        .border-bottom.mb-4.mt-4
        b-form-group#input-group-1.email-input
          b-form-input#feedback-email(v-model="rating_data.email" type="email" size="lg" v-validate="{required: false, email: true}" name="feedback-email" :state="validateState('feedback-email')" placeholder="Enter Email" required)
          b-form-invalid-feedback(:state="validateState('feedback-email')") Please enter a valid email
        b-form-group.feedback-reply
          b-form-checkbox#inquary-checkbox(v-model="rating_data.contact_me" name="inquary-checkbox") It would be ok to contact me about the feedback I provided 
        .submit-feedback.text-center 
          b-button.text-light.pl-4.pr-4(variant="primary" size="lg" @click="submitFeedback")
            span {{ $t('product.label.send') }}
      .submited-response(v-if="isSubmit")
        .msg-thank-you Thank you!
        .title-resp Thank you for providing your feedback, it has been received.
        .sub-title-resp We will continue to improve our site to make it easy for you to find what you need.
        .home-link 
          b-button.text-light.pl-md-5.pr-md-5(variant="primary" @click="goToLanding()")
            span {{ $t('product.label.continue_browsing') }}
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useCartStore } from '@/stores/cart-st';
import { useDbStore } from '@/stores/db-st';
const img = require('@/assets/images/bg-brand-device-search.svg');
export default {
  name: 'ProductInquiryModal',
  components: {
  },
  data(){
    return {
      rating_data:{
        rating: '',
        rating_type:'',
        feedback: '', 
        email: '',
        contact_me: false,
      },
      isSubmit: false,
    };
  },
  props: {
  },
  computed: {
    ...mapState(useDbStore, ['customer']),
  },
 
  created(){
    
  },

  mounted(){
    //- console.log('product**', this.product);
  },
  
  watch:{
  },

  methods:{
    selectRateing(val){
      this.rating_data.rating = val;
    },
    selectRatingType(type){
      this.rating_data.rating_type = type;
    },
    closeModal(id){
      this.$bvModal.hide(id)
    },
    goToLanding(){
      this.isSubmit = false;
      if(this.$route.name != 'Landing') this.$router.push({name: 'Landing'});
      this.closeModal('website-feedback-modal');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getStyle(){
      return `background-image: url("${img}");`;
    },
    async submitFeedback(){
      this.cart_busy = true;
      try{
        let is_valid = await this.$validator.validateAll();
        if (!is_valid) return; 
        let result = await axios.post(process.env.VUE_APP_API_BASE_URL + '/email/sendFeedback', {
          feedback_data: {
            ...this.rating_data,
            opcCustomerEmail: this.customer.email,
            opcCustomerId: this.customer.id
          }
        });
        console.log('result**', result);
        this.isSubmit = true;
        this.rating_data = {
          rating: '',
          rating_type:'',
          feedback: '', 
          email: '',
          contact_me: false,
        };
      } catch(err){
        console.error(err);
      } finally {
        this.cart_busy = false;
      }
    }
  }
  
}
</script>
<style>
  #website-feedback-modal .modal-body{
    padding: 0rem;
  }
  @media (max-width: 576px){
    #website-feedback-modal .modal-dialog{
      margin: 0 !important;
    }
  }
</style>
<style scoped lang="scss">
#website-feedback-modal{
  .feedback-modal-body {
    padding: 1rem;
    .header-title{
      font-size: 36px;
      color: #68C3C5;
      font-weight: 700;
      margin-top: 5px;
    }
    .btn-modal-close{
        position: absolute;
        right: 20px;
        top: 20px;
        @media (max-width: 767px){
          right: 15px;
          top: 15px;
        }
    }
    .header-description{
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 1rem;
      font-size:14px;
      @media (max-width: 767px){
        padding: 1rem;
        margin: 0;
      }
    }
    .user-input-wrap{
      margin: 0rem 5rem;
      @media (max-width: 991px){
        margin: 0 0.5rem;
      }
      .input-label{
        text-align: center;
        font-size:14px;
        padding: 1rem;
      }
      .user-rating-box{
        background: white;
        border-radius: 5px;
        box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
        padding: 1rem;
        margin-bottom: 1rem;
        .rete-icon-group{
          text-align: center;
          .rate-item{
            color: gray;
            margin: 0 1rem;
            font-size: 40px;
            &.active{
              color: #35C6CC;
            }
            @media (max-width: 576px){
              font-size: 38px;
              margin: 0 0.5rem;
            }
          }
        }
      }
      .rating-type-group{
        .rate-item{
          background: white;
          border-radius: 5px;
          box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
          padding: .8rem;
          margin: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 14px;
          &.active{
            border: 0.2rem solid #68C3C5;
          }
        }
      }
      .feedback-description{
        font-size:14px;
        textarea{
          font-size:14px;
        } 
      }
      .email-input{
        input{
          font-size:14px;
        }
      }
      .feedback-reply{
        font-size:14px;
      }
      .submit-feedback{
        padding-top: 1rem;
      }
    }
    .submited-response{
      text-align: center;
      margin: 3rem;
      @media (max-width: 767px){
        margin: 3rem 1rem;
      }
      .msg-thank-you{
        font-size: 36px;
        font-weight: 500;
        color: #68C3C5;
        padding: .5rem;
      }
      .title-resp{
        padding-top: .5rem;
        font-size: 14px;
      }
      .sub-title-resp{
        padding: .5rem;
        font-size: 14px;
      }
      .home-link{
        padding: .5rem;
      }
    }
  }
  
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .inquiry-modal-body{
    padding: 1rem 4rem 2rem;
    @media (max-width: 767px){
      padding: 1rem 2rem 2rem;
    }
    .input-equipment{
      label{
        font-size: 14px;
        font-weight: 500;
      }
      input{
        font-size: 14px;
      }
      textarea{
        font-size: 14px;
      }
    }
    .submited-response{
      text-align: center;
      margin: 3rem;
      @media (max-width: 767px){
        margin: 3rem 1rem;
      }
      .msg-thank-you{
        font-size: 36px;
        font-weight: 500;
        color: #68C3C5;
        padding: .5rem;
      }
      .title-resp{
        padding-top: .5rem;
        font-size: 14px;
      }
      .sub-title-resp{
        padding: .5rem;
        font-size: 14px;
      }
      .home-link{
        padding: .5rem;
      }
    }
  }
}
</style>
