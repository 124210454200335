<template lang="pug">
  //- hide-header-close 
  b-modal#product-inquiry-modal(:title="$t('product_inquiry.product')" size="lg" header-class="justify-content-center" hide-footer centered no-close-on-backdrop no-close-on-esc)
    b-container.inquiry-modal-body
      b-form(v-if="!isSubmit")
        .preloaproduct-info-data 
          .item-info {{ $t('product_inquiry.sku') }}: 
          .item-info {{ $t('product_inquiry.description') }}: 
            span {{ translateText(product, 'description')}} 
        b-row.input-equipment
          b-col.mt-2(cols="12")
            .h5.equipment-label {{ $t('product_inquiry.equipment') }}:

          b-col(cols="12" md="6")
            b-form-group#input-group-1(label="OEM:" label-for="inquary-brand")
              b-form-input#inquary-brand(type="text" v-model="oem" v-validate="{required: false}" name="inquary-brand" :state="validateState('inquary-brand')" placeholder="Enter OEM" required)
          
          b-col(cols="12" md="6")
            b-form-group#input-group-2(:label="$t('inquiry_form.model')" label-for="inquary-model")
              b-form-input#inquary-model(type="text" v-model="model" v-validate="{required: false}" name="inquary-model" :state="validateState('inquary-model')" :placeholder="$t('product_inquiry.model')" required)

          b-col(cols="12")
            b-form-group#input-group-1(:label="$t('product_inquiry.inquiry')" label-for="inquary-description")
              b-form-textarea#inquary-description(v-model="inquiry" v-validate="{required: true}" name="inquary-description" :state="validateState('inquary-description')" :placeholder="$t('product_inquiry.description')" required)
              b-form-invalid-feedback(:state="validateState('inquary-description')") Please enter inquiry details

          b-col.mt-2(cols="12")
            .h5 {{ $t('product_inquiry.contact_details') }}:

          b-col(cols="12" md="4")
            b-form-group#input-group-1(:label="$t('quote_modal.name')" label-for="inquary-name")
              b-form-input#inquary-name(type="text" v-model="name" v-validate="{required: true}" :state="validateState('inquary-name')" name="inquary-name" :placeholder="$t('quote_modal.enter_name')" required)
              b-form-invalid-feedback(:state="validateState('inquary-phone')") {{$t('validation.name')}}

          b-col(cols="12" md="4")
            b-form-group#input-group-1(:label="$t('quote_modal.phone_number')" label-for="inquary-phone")
              b-form-input#inquary-phone(type="number" v-model="phone" v-validate="{required: isPhoneReqired}" :state="validateState('inquary-phone')" name="inquary-phone" :placeholder="$t('quote_modal.enter_phone_number')" required)
              b-form-invalid-feedback(:state="validateState('inquary-phone')") {{ $t('validation.phone_number') }}

          b-col(cols="12" md="4")
            b-form-group#input-group-1(:label="$t('quote_modal.email')" label-for="inquary-email")
              b-form-input#inquary-email(type="email" v-model="email" v-validate="{required: isEmailReqired, email: true}" name="inquary-email" :state="validateState('inquary-email')" :placeholder="$t('quote_modal.enter_email')" required)
              b-form-invalid-feedback(:state="validateState('inquary-email')") {{ $t('validation.email') }}

          b-col.mt-2(cols="12")
            b-form-group(:label="$t('product_inquiry.preferred_method_contact')")
              b-form-checkbox-group#inquary-checkbox(v-model="preferred_contact" v-validate="{required: true}" name="inquary-checkbox" :state="validateState('inquary-checkbox')")
                b-form-checkbox(value="phone") {{ $t('inquiry_form.phone') }}
                b-form-checkbox(value="email") {{ $t('inquiry_form.email') }}
                b-form-invalid-feedback(:state="validateState('inquary-checkbox')") {{ $t('validation.option') }}
          b-col(cols="12")
            b-form-group#input-group-1(:label="$t('product_inquiry.company_name')" label-for="inquary-company")
              b-form-input#inquary-company(type="text" v-model="company" v-validate="{required: true}" name="inquary-company" :state="validateState('inquary-company')" :placeholder="$t('product_inquiry.enter comapny_name')" required)
              b-form-invalid-feedback(:state="validateState('inquary-company')") {{ $t('validation.company_name') }}

          b-col.mt-3.text-center(cols="12")
            b-button.text-light.pl-4.pr-4(variant="primary" @click="submitCart")
              span {{ $t('product.label.submit') }}

      .submited-response(v-else)
        .msg-thank-you {{ $t('product.label.thankyou') }}
        .title-resp {{ $t('product.label.thankyou_submitting') }}
        .sub-title-resp {{ $t('product.label.we_will_reach') }}
        .home-link 
          b-button.text-light.pl-md-4.pr-md-4(variant="primary" :to="{name: 'Landing'}")
            span {{ $t('product.label.continue_browsing') }}

</template>

<script>
import axios from 'axios';
import { useDbStore } from '@/stores/db-st';
import { mapState, mapActions } from 'pinia';
import utils from "@/mixins/utils";
import { useCartStore } from '@/stores/cart-st';
export default {
  name: 'ProductInquiryModal',
  mixins: [utils],
  data(){
    return {
      oem: '',
      model: '',
      inquiry: '',

      name: '',
      phone:'',
      email: '', 
      preferred_contact: [],
      company: '',
      isSubmit: false,
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useDbStore, ['customer']),
    ...mapState(useCartStore, ['cart_contact','cart_items']),

    isPhoneReqired(){
      if(this.preferred_contact.indexOf('phone') != -1){
        return true;
      }
      return false;
    },

    isEmailReqired(){
      if(this.preferred_contact.indexOf('email') != -1){
        return true;
      }
      return false;
    },
  },
 
  created(){
    
  },

  mounted(){
    //- console.log('product**', this.product);
  },
  
  watch:{
  },

  methods:{
    ...mapActions(useCartStore, ['emptyCart','hydrateCart','removeCartItem','updateCartItem','setCartContact',]),
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    
    async submitCart(){
      this.cart_busy = true;
      try{
        let is_valid = await this.$validator.validateAll();
        if (!is_valid) return; 
        let item = {
          id: this.product.id,
          name: this.product.name_en,
          oem: this.product.oem,
          sku: this.product.sku,
          quantity: 1,
          description:this.product.description_en,
          oem: this.oem,
          model: this.model,
          inquiry: this.inquiry,
        }
        let contact = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          company: this.company,
          preferred_contact: this.preferred_contact
        }
        let result = await axios.post(process.env.VUE_APP_API_BASE_URL + '/email/productinquiryForm', {
          product: item,
          contact: contact,
          opcCustomerEmail: this.customer.email,
          opcCustomerId: this.customer.id,
          marketingRegionId: this.customer.marketing_region_id
        });
        console.log('result**', result);
        this.isSubmit = true;
      } catch(err){
        console.error(err);
      } finally {
        this.cart_busy = false;
      }
    }
  }
  
}
</script>
<style scoped lang="scss">
#product-inquiry-modal{
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .inquiry-modal-body{
    .preloaproduct-info-data {
      font-size: 15px;
      .item-info{
        font-Weight: 600;
        font-size: 15px;
        margin-bottom: 10px;
        span{
          color: gray;
          font-Weight: 500;
        }
      }
    }
    .submited-response{
      text-align: center;
      margin: 3rem;
      @media (max-width: 767px){
        margin: 3rem 1rem;
      }
      .msg-thank-you{
        font-size: 36px;
        font-weight: 500;
        color: #68C3C5;
        padding: .5rem;
      }
      .title-resp{
        padding-top: .5rem;
        font-size: 14px;
      }
      .sub-title-resp{
        padding: .5rem;
        font-size: 14px;
      }
      .home-link{
        padding: .5rem;
      }
    }
  }
}
</style>
