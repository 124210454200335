<template lang="pug">
b-container#search-product-item(fluid)
  b-alert(:show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;")
    h6 {{msg}}
  b-row.search-item-wrap.pt-4.pb-4
    b-col.search-item-img-block(cols="4" md="3" lg="2")
      .search-product-carousel-wrap
        VueSlickCarousel(v-bind="settingsImageView" name="product.id")
          template(#prevArrow="arrowOption")
            b-img.custom-arrow(src="@/assets/images/btn-left.svg")
          .carousel-item.m-1(v-for="pimage, idx in imagesForProduct(product.id)" :key="idx" @click="toDetails(product.id)")
            b-img.rounded.carousel-item-img( v-if="pimage.image_link" :src="getUrl(pimage.image_link)")

          .carousel-item.m-1(v-if="!imagesForProduct(product.id) || imagesForProduct(product.id).length == 0" @click="toDetails(product.id)")
            b-img.rounded.carousel-item-img( :src="getUrl('')")
          template(#nextArrow="arrowOption")
            b-img.custom-arrow(src="@/assets/images/btn-right.svg")
                  
      //- b-carousel.text-center#imgs-carousel( controls indicators background="#8e8e8e" :interval="0" )
      //-   b-carousel-slide( v-for="pimg in imagesForProduct(product.id)" :key="pimg.id" caption="" :text="pimg.image_type" img-blank-color="#3e3e3e" )
      //-     template(#img)
      //-       b-img(fluid :src="pimg.image_link" style="height: 180px; max-height: 180px;")

    b-col.search-item-details-block(cols="8" md="9" lg="10" @click="toDetails(product.id)")
      TranslateValue(:sourceObj="product")
      //- .product-details {{ getDerivedOem(product) }}
      b-row.prod-spec-group 
        b-col.prod-spec-left(cols="12" md="7")
          .item-group
            .list-item.mb-2.mb-md-3(@click.stop="")
              span.dot(:style="primaryBgColor")
              //- b-img.bullet(src="@/assets/images/bullet.svg")
              span.item-label.pl-1 {{ $t('search.label.order_condition') }} :
              span.item-value
                b-form-select(size="sm" v-model="condition")
                  b-form-select-option(value="") --- Select ---
                  b-form-select-option(:value="searchItem.id")
                   TranslateValue(:sourceObj="searchItem" objKey="product_type")
                  b-form-select-option(v-for="p in searchItem.options" :value="p.id" :key="'option'+p.id") {{p.product_type_en}}
                  //- b-form-select-option(value="Refurnished") Refurnished
            .list-item.mb-1
              span.dot(:style="primaryBgColor")
              span.item-label.pl-1 {{ $t('search.label.sku') }}
              span.item-value(:style="secondaryColor") {{ product.customer_sku || product.sku }}
            .list-item
              span.dot(:style="primaryBgColor")
              span.item-label.pl-1 {{ $t('search.label.oem_brand') }}
              span.item-value(:style="secondaryColor") 
                TranslateValue(:sourceObj="product" objKey="oem_brand")
              //- span.item-value(:style="secondaryColor") {{ product.oem_brand_en }} 
            .list-item
              span.dot(:style="primaryBgColor")
              span.item-label.pl-1 {{ $t('search.label.oem_number') }}
              span.item-value(:style="secondaryColor") {{ product.oem }}
            
        b-col.prod-spec-right(cols="12" md="5")
          .item-group 
            .list-item.mb-2
              span.dot(:style="primaryBgColor")
              span.item-label.pl-1 {{ $t('search.label.price') }}
              span.item-value(v-if="!shouldShowListPrice || !Number(translatePrice(product, 'list_price'))" @click.stop="showListPrice = true") 
                span.request-list-price {{ $t('global.label.request_quote') }}
              span.item-value(v-else) 
                span.price {{ getCurrency }}{{ Number(translatePrice(product,"list_price")).toFixed(2)}}/
                span.price-unit {{getBuyingUnit(product.packaging_factor)}}
            .list-item.d-flex.align-items-baseline()
              .quantity-input.w-25(@click.stop="")
                b-form-input(type="number" v-model="my_quantity" min="1" size="sm")
              .add-to-cart.w-75
                b-button.add-to-cart-btn.text-light.ml-1(:style="primaryBgColor" variant="primary" size="sm" @click.stop="itemChanged")
                  span {{ $t('search.label.add_to_cart') }}
                .stock-details.pl-1(v-if="displayStockInfoForCustomer")
                  .in-stock(v-if="product_stock > 0") {{(product_stock > 100)? "100+": product_stock}} {{$t('search.label.in_stock')}}
                  .out-of-stock(v-if="product_stock == 0") {{ $t('search.label.out_of_stock') }}
                  .out-of-stock-desc(v-if="product_stock == 0") ({{ $t('global.label.average_lead_time') }} 2-4 {{ $t('global.label.weeks') }})


//- b-container(fluid).border-bottom.p-3
//-   b-row(no-gutters)
//-     .col-3.border.border-1
//-       b-carousel.text-center#imgs-carousel( controls indicators background="#8e8e8e" :interval="0" )
//-         b-carousel-slide( v-for="pimg in imagesForProduct(product.id)" :key="pimg.id" caption="" :text="pimg.image_type" img-blank-color="#3e3e3e" )
//-           template(#img)
//-             b-img(fluid :src="pimg.image_link" style="height: 180px; max-height: 180px;")
//-     .col.pl-2(@click="toDetails(product.id)")
//-       h5 {{product.name_en}}
//-       small {{ getDerivedOem(product) }}
//-       b-table-simple.text-secondary(small borderless)
//-         b-tbody
//-           b-tr
//-             b-td {{ $t('search.label.oem_brand') }}
//-             b-td {{ product.oem_brand_en }}
//-           b-tr
//-             b-td {{ $t('search.label.oem_number') }}
//-             b-td {{ product.oem }}
//-           b-tr
//-             b-td {{ $t('search.label.order_number') }}
//-             b-td {{ product.sku }}
//-       b-container(fluid).small
//-         .text-dark {{ $t('search.label.product_type') }}
//-         .d-flex.justify-content-between
//-           div 
//-             b-icon-circle-fill.text-primary( v-if="product.product_type_id==1")
//-             b-icon-circle(v-else)
//-             span.ml-1 {{ $t('search.label.oem_signal') }} 
//-           div 
//-             b-icon-circle-fill.text-primary( v-if="product.product_type_id==2")
//-             b-icon-circle(v-else)
//-             span.ml-1 {{ $t('search.label.new_replacement') }}
//-           div 
//-             b-icon-circle-fill.text-primary( v-if="product.product_type_id==3")
//-             b-icon-circle(v-else)
//-             span.ml-1 {{ $t('search.label.refurbish') }}
//-           div 
//-             b-icon-circle-fill.text-primary( v-if="product.product_type_id==4")
//-             b-icon-circle(v-else)
//-             span.ml-1 {{ $t('search.label.oem_as_it_was') }}
//-     .col-2
//-       .text-dark.h4 {{ Number(product.list_price_us).toLocaleString('en-US', { style:'currency', currency: 'USD' }) }}
//-         span.small.text-dark /each
//-       table
//-         tr
//-           td.small {{ $t('search.label.unit') }}
//-           td.small {{ product.packaging_factor }} {{ $t('search.label.pieces_per_bag') }}
//-         tr
//-           td.small {{ $t('search.label.quantity') }}
//-           td
//-             b-input-group( size="sm" )
//-               b-form-input( style="min-width: 40px;" type="number" v-model="my_quantity" min="1" @change="itemChanged" )
//-               b-input-group-append
//-                 b-button( size="sm" variant="outline-secondary" @click="itemChanged") {{ $t('search.label.add_to_cart') }}
//-         tr
//-           td(colspan="2" style="font-weight: bold;").small {{ $t('search.verbiage.next_day_shipping') }}
     
</template>

<script>
import { mapActions, mapState, mapGetters } from 'pinia';
import { useCartStore } from '../stores/cart-st';
import { useAuthStore } from '../stores/auth-st';
import { useDbStore } from '../stores/db-st';
import VueSlickCarousel from 'vue-slick-carousel';
import utils from "@/mixins/utils";
import productaddons from "@/mixins/product-addons";
import TranslateValue from '@/components/TranslateValue.vue';
export default {
  name: 'SearchResultItem',
  components: {
    VueSlickCarousel,
    TranslateValue
  },
  mixins: [utils, productaddons],
  props: {
    searchItem: {required: true, type: Object},
    images: {required: true, type: Array}
  },
  data(){ 
    return {
      showListPrice: false,
      my_quantity: 1,
      dismissCountDown: 0,
      msg: '',
      condition: '',
      settingsImageView:{
        dots: false,
        arrows: true, 
        infinite: false,
        nextArrow: '<button class="carousel-arrow next inverted"></button>',
        prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: true,
        variableWidth: false,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              dots: true,
              arrows: false, 
              infinite: false,
              //- nextArrow: '<button class="carousel-arrow next inverted"></button>',
              //- prevArrow: '<button class="carousel-arrow prev inverted"></button>',
              slidesToShow: 1,
              slidesToScroll: 1,
              swipeToSlide: true,
              touchMove: true,
              variableWidth: false,
            }
          }
        ]
        //- responsive: [
        //-   {
        //-     breakpoint: 1200,
        //-     settings: {
        //-       slidesToShow: 4,
        //-       slidesToScroll: 4,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-       nextArrow: '<button class="carousel-arrow next inverted"></button>',
        //-       prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        //-     }
        //-   },
        //-   {
        //-     breakpoint: 1024,
        //-     settings: {
        //-       slidesToShow: 3,
        //-       slidesToScroll: 3,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-     }
        //-   },
        //-   {
        //-     breakpoint: 678,
        //-     settings: {
        //-       slidesToShow: 2,
        //-       slidesToScroll: 2,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-     }
        //-   }
        //- ]
      },
    };
  },  
  watch:{
    searchItem(now, then){
      this.condition = this.product.id;
      if(this.searchItem.product_type_en != "Replacement"){
        this.searchItem.options.forEach((item)=>{
          if(item.product_type_en == "Replacement"){
            this.condition = item.id;
          }
        });
      }
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isLoggedIn']),
    ...mapState( useDbStore, ['brands','categories','filter_options'] ),
    ...mapState( useCartStore, ['cart_items']),
    ...mapGetters(useDbStore, ['isMedtenCustomer', 'customer',  'primaryColor', 'secondaryColor', 'primaryBgColor', 'secondaryBgColor', 'primaryBgColorWithoutBorder', 'secondaryBgColorWithoutBorder', 'secondaryBorder']),
    product(){
      if(this.searchItem.id != this.condition){
        let tempItem = this.searchItem.options?.find(p => p.id == this.condition);
        if(tempItem) return tempItem;
      }
      return this.searchItem;
    },
    shouldShowListPrice() {
      return this.getShouldShowListPrice(this.isParts(this.product.category_id), this.isLoggedIn, this.showListPrice)
    },
    product_stock(){
      //- console.log('this.getProductStock(this.product)', this.getProductStock(this.product), this.product)
      return this.getProductStock(this.product); // from mixin
    }
  },
  created(){
    this.condition = this.product.id;
    if(this.searchItem.product_type_en != "Replacement"){
      this.searchItem.options.forEach((item)=>{
        if(item.product_type_en == "Replacement"){
          this.condition = item.id;
        }
      });
    }
    if(this.cart_items){
      let match = this.cart_items.find(ci=>ci.id===this.product.id);
      if(match){
        this.my_quantity = match.quantity;
      }
    }
  },
  mounted(){
    setTimeout(()=>{
      const element = document.getElementsByClassName("carousel-item-img")
      element.forEach(function(item){
        if(item) item.style.height = (item.offsetWidth-5) + "px";
          //- console.log(item.offsetWidth);
      })
    }, 200);
  },
  methods:{
    ...mapActions( useCartStore, ['createCartItem','findCartItem','removeCartItem','updateCartItem']),
    getBuyingUnit(packaging_factor){
      if (!packaging_factor || packaging_factor == 1) return "ea";
      else if (packaging_factor == "Set") return "set";
      else return "box of " + packaging_factor;
    },
    isParts(id){
      let category = this.categories.find(c=>c.id==id);

      // todo it is a issue when there is product whose category or parent category is not published
      if(!category) {
        return false
      }
      let parent_id = category['parent_id'];
      while(parent_id != 1 && parent_id != 2 && category){ 
        category = this.categories.find(c=> c.id == parent_id);
        parent_id = category['parent_id'];
      }
      if(parent_id == 2){
        return true;
      }else{
        return false;
      }
    },
    getUrl(url){
      if(url) return this.getFullUrl(url);
      return require(`@/assets/images/Default_Missing_Image.png`);
    },
    getDerivedOem(){
      let verbiage = `${(this.product.oem_brand_en||"")} ${(this.product.oem||"")}`.trim();
      if( this.product.product_type_id == 1 ){
        return verbiage; 
      } else if (  this.product.product_type_id == 4 ){
        return verbiage ? `${verbiage} OEM as is` : "";
      } else {
        return verbiage ? `Replaces: ${verbiage}`: "";
      }
    },
    imagesForProduct(pid){
      if(!this.images) return [];
      const fullImagesType = ['Full Product', 'Full Product', 'Full Product - PS']
      let fullImgs = this.images.filter(pimg => { 
        return (pimg.product_id == pid && fullImagesType.includes(pimg.image_type));
      });
      let imgs = this.images.filter(pimg=> { 
        return (pimg.product_id == pid && !fullImagesType.includes(pimg.image_type));
      });
      if (this.product.family_image_link) { 
        imgs.push({
          image_link: this.product.family_image_link
        });
      }
      return [...fullImgs, ...imgs];
    },
    
    itemChanged(){
      let item = this.findCartItem(this.product.id);
      if(item){
        if(this.my_quantity == 0 ) {
          this.removeCartItem(this.product.id);
        } else {
          this.updateCartItem(this.product.id, this.my_quantity);
        }
      } else {
        let image_link = this.imagesForProduct(this.product.id).length ? this.imagesForProduct(this.product.id)[0].image_link : '';
        let item = {
          id: this.product.id, 
          name_en: this.product.name_en,
          name_zh: this.product.name_zh, 
          image_url: image_link,
          description_en: this.product.description_en,
          description_zh: this.product.description_zh, 
          oem: this.product.oem,
          sku: this.product.customer_sku || this.product.sku,
          price: this.isParts(this.product.category_id) ? 'Request quote' : this.translatePrice(this.product,"list_price"),
          quantity: this.my_quantity
        };
        //- console.log(item, this.product);
        this.createCartItem(item);
      }
      this.my_quantity = 1;
      this.msg=this.$t('global.label.Item_successfully');
      this.showAlert();
    },
    showAlert() {
      this.dismissCountDown = 3;
    },
    toDetails(){
      this.$router.push({ name: 'Product', params: {product_id:  this.product.id} })
    },
  },
}
</script>
<style lang="scss">
#search-product-item{
  .request-list-price {
      color: #8D8601; 
      font-weight: bold
    }
  .search-product-image .slick-slider .slick-prev{
    width: 40px !important;
  }
  .search-product-image .slick-slider .slick-next{
    width: 40px !important;
  }
  .search-item-img-block{
    padding-right: 0rem;
    @media (max-width: 767px){
      padding: 0px !important;
    }
    @media (min-width: 1700px){
      flex: 0 0 200px;
      max-width: 200px;
    }
    .search-product-carousel-wrap{
      .slick-slider{
        .slick-prev{
          width: 40px !important; 
          left: -20px !important;
          &:before{
            color: gray !important;
          }
        }
        .slick-next {
          width: 40px !important; 
          right: -20px !important;
          &:before{
            color: gray !important;
          }
        }
        .slick-list{
          .slick-track{
            .slick-slide{
              .carousel-item{
                cursor: pointer;
                img{
                  width: 95% !important;
                  box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
                  border-radius: 5px;
                  height: auto;
                  @media (max-width: 767px){
                    width: 94% !important;
                    min-height: 110px;
                  }
                }
              }
            }
          }
        }
        .slick-dots{
          li{
            width: 7px;
          }
        }
      }
      .carousel-item{

      }
    }
  }
}


.search-product-image{
  
}
</style> 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#search-product-item{
  .search-item-wrap{
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
    @media (max-width: 540px){
      padding-bottom: .7rem !important;
      padding-top: .7rem !important;
    }
    .search-item-details-block{
      cursor: pointer;
      padding-left: 2rem;
      @media (max-width: 540px){
        padding-left: 15px;
        .product-name{
          font-size: 12px !important;
        }
        .product-details{
          font-size: 10px !important;
        }
      }
      .product-name{
        font-size: 18px;
        font-weight: 700;
      }
      .product-details{
        font-size: 12px;
        color: #525252;
      }
      .prod-spec-group{
        margin-top: 0.2rem;
        .prod-spec-left{
          @media (max-width: 540px){
            .item-group{
              .list-item{
                .item-label{
                  font-size: 10px !important;
                }
                .item-value{
                  font-size: 10px !important;
                  select{
                    width: 50%  !important;
                  }
                }
              }
            }
          }
          .item-group{
            .list-item{
              .bullet{

              }
              .item-label{
                display: inline-block;
                width: 90px;
                font-size: 14px;
                font-weight: 500;
                color: #525252;
              }
              .item-value{
                font-size: 14px;
                font-weight: 600;
                color: #38BCD9;
                select{
                  width: 40%;
                }
              }
            }
          }
        }
        .prod-spec-right{
          display: flex-end;
          margin-top: 1rem;
          @media (max-width: 540px){
            margin-top: .5rem;
            .item-group{
              .list-item{
                margin-top: .5rem;
                .item-label{
                  font-size: 10px !important;
                }
                .item-value{
                  font-size: 10px !important;
                  .price{
                    font-size: 16px !important;
                  }
                  .price-unit{
                    font-size: 10px !important;
                  }
                }
              }
              .stock-details{
                .in-stock{
                  font-size: 10px;
                }
                .out-of-stock{
                  font-size: 10px;
                }
                .out-of-stock-desc{
                  font-size: 10px;
                }
              }
            }
          }
          .item-group{
            .list-item{
              display: flex;
              align-items: center;
              .item-label{
                display: inline-block;
                width: 90px;
                font-size: 14px;
                font-weight: 500;
                color: #525252;
              }
              .item-value{
                .price{
                  font-size: 24px;
                  font-weight: 600;
                  color: #272727;
                }
                .price-unit{
                  font-size: 14px;
                  color: #7F7F7F;
                }
              }
              .add-to-cart{
                .add-to-cart-btn{
                  
                }
                .stock-details{
                  .in-stock{
                    font-weight: 600;
                    font-size: 14px;
                    color: #14A757;
                    margin-top: 2px;
                  }
                  .out-of-stock{
                    font-weight: 600;
                    font-size: 14px;
                    color: #7F7F7F;
                    margin-top: 2px;
                  }
                  .out-of-stock-desc{
                    font-weight: 600;
                    font-size: 12px;
                    color: #7F7F7F;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
