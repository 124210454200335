<template lang="pug">
b-container.p-0(fluid)
  FilterTreeNode( :node="nodes[0]" :selection="selection" @select="handleSelection" @deselect="handleDeselection" )

</template>

<script>
import FilterTreeNode from './FilterTreeNode';
export default {
  name: 'FilterTree',
  components: {
    FilterTreeNode
  },
  props: {
    nodes: {
      // the set of all nodes (e.g. categories) that are available in the tree. Should be a a tree-map 
      type: Array,
      required: true,
    },
    'initial-selection': {
      // the set of all node ids (e.g. category ids) that are initially selected in the tree.
      type: Array,
      required: false,
      default: ()=>{return[];},
    },
  },
  data(){
    return {
      selection: [], // the current set of node ids that are selected
      local_value: null,
    };
  },
  
  // computed: { },

  created(){
    this.selection = this.initialSelection;
    this.setVisibility(this.nodes[0]);
  },
 
  methods:{
    // ...mapActions( useDbStore, ['productsWithinCategory']),
    handleDeselection( ids ){
      ids.forEach( remove => {
        let x = this.selection.findIndex( id => id === remove );
        if( x >= 0 ) this.selection.splice(x, 1);
      });
      this.$emit('selection-changed', this.selection);
      // console.log(this.selection);
    },
    handleSelection( ids ){
      ids.forEach( add => {
        let x = this.selection.findIndex( id => id === add );
        if( x < 0 ) this.selection.push(add);
      });
      this.$emit('selection-changed', this.selection);
      // console.log(this.selection);
    },
    setVisibility( node ){
      let found = this.countSelectedDescendents( node );
      node.visible = found !== 0;
      node.nodes.forEach( n => {
        this.setVisibility( n ); 
      });
    },
    countSelectedDescendents( node ){
      let count = 0;

      if( this.selection.includes(node.id) ) count++;

      node.nodes.forEach( n => {
        count += this.countSelectedDescendents( n ); 
      });

      return count;
    },
    
  },
}
</script>
