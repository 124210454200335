const setExpiryTimeInLocalStorage = function () {
  let currentDate = new Date().getTime() + 43200000;
  localStorage.setItem("ExpiryTime", currentDate);
};

export const clearLSAfterExpiryTime = function () {
  const expiryTime = localStorage.getItem("ExpiryTime");
  if (expiryTime) {
    const isExpired = new Date().getTime() > Number(expiryTime);

    if (isExpired) {
      localStorage.clear();
      setExpiryTimeInLocalStorage();
      window.location.reload(true);
    } else {
      setExpiryTimeInLocalStorage();
      console.log('Reset expiry time')
    }
  } else {

    setExpiryTimeInLocalStorage();
    
  }
};

