<template lang="pug">
b-container#app(fluid).p-0(:class="{'is-medten-customer': isMedtenCustomer}" v-show="customer")
  Onlinechat
  Header
  b-overlay(:show="has_global_error")
    template( #overlay )
      div.text-center.text-danger
        b-icon-exclamation-triangle
        p {{global_error}}
    router-view
</template>

<script>
import { mapState, mapActions } from 'pinia';
import Header from '@/components/Header.vue';
import Onlinechat from '@/components/Online-chat.vue'
import { useDbStore } from '@/stores/db-st';
import { useBaseStore } from '@/stores/base-st';
export default {
  name: 'App',
  components: {
    Header,
    Onlinechat 
  },
  data(){
    return {

    };
  },

  computed: {
    ...mapState(useBaseStore, ['message','error','errors','busy','global_error','has_error','has_global_error','has_message']),
    ...mapState(useDbStore, ['customer','isMedtenCustomer']),
  },
 
  async created(){
    // "silent" login
    this.hydrateCustomer();
    this.loadLifecycles();
    this.setFavicons();
    this.setTitle();
    if(!this.$router.currentRoute || !this.$router.currentRoute.name ) this.$router.push({name: 'Landing'}); 
  },
  
  watch:{
  },
  
  methods:{
    ...mapActions(useDbStore, ['hydrateCustomer', 'loadLifecycles']),
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    setTitle() {
      const medtenSites = [1,2,3]
      if (!medtenSites.includes(this.customer?.id)) {
        document.title = 'Product Catalog'
      } 
    },
    setFavicons() {
      if(!this.isMedtenCustomer){
        const faviconEle = document.querySelector('#favicon')
        faviconEle.href = "./favicon-brands.ico";
        return
      }
      if (window.location.hostname.indexOf('test.') > -1) {
        const faviconEle = document.querySelector('#favicon')
        faviconEle.href = "./favicon-test.ico";
      }
    },
  }
  
}
</script>
<style>
.custom-arrow{
  z-index: 1 !important;
  width: auto !important;
  height: auto !important;
  top: 45% !important;
  
}
.dot{
  height: 8px;
  width: 8px;
  background-color: #38bcd8;
  border-radius: 50%;
  border: 1px solid #38bcd8;
  display: inline-block;
  margin-right: 4px;
}
.custom-arrow.slick-disabled{
  opacity: .5;
}
.desktop-show{
  display: none !important;
}
.mobile-show{
  display: flex;
}
mark, .mark{
  padding: 0px;
  background-color: #FAF11D;
}
@media (min-width: 768px){
  .desktop-show{
    display: block !important;
  }
  .mobile-show{
    display: none !important;
  }
}
#app:not(.is-medten-customer) ~ #chat-widget-container {
  display: none;
}
#landing .carousel-image img {
  
  width: 1200px !important;
  margin:auto
}
</style> 